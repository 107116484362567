import React from 'react';

const config = {
    'machine': {
        name: 'Machine Working'
    },
    'beans': {
        name: 'Beans'
    },
    'milk': {
        name: 'Milk'
    },
    'hot_chocolate': {
        name: 'Hot Chocolate'
    }
}

const ChangeStatus = (props) => {
    return (
        <>
            <div class="flex flex-wrap">
                <span class="flex md:justify-center items-center text-gray-800 font-bold border-gray-200 text-lg md:text-xs  md:w-2/12 sm:w-full overflow-hidden">
                    <p>{config[props.item].name}</p>
                </span>
                <button
                    key='top_q_y'
                    className="flex justify-center text-gray-800 font-bold border border-gray-200 text-xs sm:text-sm  w-full md:w-5/12 background-green-200 rounded-full overflow-hidden transition-colors duration-300 hover:bg-primary/10 hover:border-primary/20 disabled:bg-white disabled:cursor-not-allowed disabled:text-gray-300"
                onClick={() => props.callback(props.item, true)}
                >
                    <span className="p-2 sm:p-3">Yes</span>
                </button>

                <button
                    key='top_q_n'
                    className="flex justify-center text-gray-800 font-bold border border-gray-200 text-xs sm:text-sm w-full  md:w-5/12  rounded-full overflow-hidden transition-colors duration-300 hover:bg-primary/10 hover:border-primary/20 disabled:bg-white disabled:cursor-not-allowed disabled:text-gray-300"
                    onClick={() => props.callback(props.item, false)}
                >
                    <span className="p-2 sm:p-3">No</span>
                </button>
            </div>
        </>
    );
};

export default ChangeStatus;
