import React from 'react';

const color_map = {
    'Okay': 'bg-green-500',
    'Operational': 'bg-green-500',
    'Offline': 'bg-red-500',
    'None': 'bg-red-500',
}

const StatusElement = (props) => {
    return (
        <>
            {props.fullWidth ?
                <>
                    <div class="mt-4 w-full px-5 mb-4">
                        <div class="relative flex flex-col min-w-0 break-words bg-white rounded mb-3 xl:mb-0 shadow-lg">
                            <div class="flex-auto p-4">
                                <div class="flex flex-wrap">
                                    <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                                        <h5 class="text-blueGray-400 uppercase font-bold text-xs">{props.status}</h5>
                                        <span class="font-semibold text-xl text-blueGray-700">{props.title}</span>
                                    </div>
                                    <div class="relative w-auto pl-4 flex-initial">
                                        <div className={["text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full", color_map[props.status]].join(' ')}>
                                            <i class="fas fa-chart-bar"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> :
                <>
                    <div class="mt-4 w-full lg:w-6/12 xl:w-4/12 px-5 mb-4">
                        <div class="relative flex flex-col min-w-0 break-words bg-white rounded mb-3 xl:mb-0 shadow-lg">
                            <div class="flex-auto p-4">
                                <div class="flex flex-wrap">
                                    <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                                        <h5 class="text-blueGray-400 uppercase font-bold text-xs">{props.status}</h5>
                                        <span class="font-semibold text-xl text-blueGray-700">{props.title}</span>
                                    </div>
                                    <div class="relative w-auto pl-4 flex-initial">
                                        <div className={["text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full", color_map[props.status]].join(' ')}>
                                            <i class="fas fa-chart-bar"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
        </>
    );
};

export default StatusElement;
